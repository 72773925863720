import { Box, Typography } from '@mui/material';
import React from 'react';

import TranslatorUtil from '../utils/translator_util';
import character from '../resources/character.png';

const NotFoundPage = () => {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("notFound_page"));

    TranslatorUtil.setCallback("notFound_page", () => {
        setTexts(TranslatorUtil.getComponentTexts("notFound_page"));
    });

    return (
        <Box sx={{ width: "80vw", maxWidth: "600px", margin: "0 auto" }}>
            <Box sx={{ width: "80vw", maxWidth: "400px", margin: "0 auto" }} >
                <img src={character} style={{ width: "100%" }} alt="site character" />
            </Box>
            <Box sx={{ height: "20px" }} />
            <Typography sx={{ fontSize: "6rem", fontWeight: "bold", textAlign: "center" }}>
                Oops!
            </Typography>
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {texts["404"]}
            </Typography>
            <Typography sx={{ fontSize: "0.9rem", color: 'text.secondary' }}>
                {texts["text"]}
            </Typography>
        </Box >
    );
}

export default NotFoundPage;
