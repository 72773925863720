import { Box, Typography } from '@mui/material';
import React from 'react';

import character from '../resources/character.png';
import TranslatorUtil from '../utils/translator_util';

const AboutPage = () => {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("about_page"));

    TranslatorUtil.setCallback("about_page", () => {
        setTexts(TranslatorUtil.getComponentTexts("about_page"));
    });

    return (
        <Box sx={{ width: "80vw", maxWidth: "600px", margin: "0 auto" }}>
            <Box sx={{ width: "80vw", maxWidth: "400px", margin: "0 auto" }} >
                <img src={character} style={{ width: "100%" }} alt="site character" />
            </Box>
            <div style={{ height: "20px" }}></div>
            <Typography variant='h1'>{texts["about"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p1"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p2"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p3"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p4"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p5"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p6"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ fontWeight: "bold", color: 'text.primary' }}>{texts["p7"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p8"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>{texts["p9"]}</Typography>
        </Box >
    );
}

export default AboutPage;
