import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/system';
import { blue, purple } from '@mui/material/colors';
import AppBarComponent from './components/appBar_component';
import { Outlet } from 'react-router-dom';
import FooterComponent from './components/footer_component';
import TranslatorUtil from './utils/translator_util';


function App() {
  const [lang, setLang] = React.useState("en");

  const langsAvaliable = ["en", "pt"];
  const STORAGE_LANG_KEY = "user-choise-lang";
  ;
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          background: {
            default: "rgb(0,30, 60)",
            paper: "rgb(0,30, 60)",
          },
          primary: {
            main: blue[700],
          },
          secondary: {
            main: purple[500],
          },
        },
        typography: {
          h1: {
            fontSize: "1.5rem",
            fontWeight: "bold",
          },
          h2: {
            fontSize: "1.2rem",
            fontWeight: "bold",
          },
        },
      }),
    [],
  );

  //console.log(theme);

  function getNavigatorLanguage() {
    return window.navigator.language.toLocaleLowerCase().substring(0, 2);
  }

  function initialLang() {
    let lang = "en";

    if (localStorage.getItem(STORAGE_LANG_KEY)) {
      lang = prepareLang(localStorage.getItem(STORAGE_LANG_KEY));
    } else {
      lang = prepareLang(getNavigatorLanguage());
      localStorage.setItem(STORAGE_LANG_KEY, lang)
    }

    TranslatorUtil.setLang(lang);

    return lang;
  }

  function prepareLang(lang) {
    let preparedLang = "en";

    for (const key in langsAvaliable) {

      if (lang === langsAvaliable[key]) {
        preparedLang = lang;
        break;
      }

    }

    return preparedLang;
  }

  function handleChangeLang(lang) {
    let langToSave = prepareLang(lang);
    localStorage.setItem(STORAGE_LANG_KEY, langToSave);
    setLang(langToSave);
    TranslatorUtil.setLang(langToSave);
    //console.log(langToSave);
  }

  React.useEffect(() => {
    setLang(initialLang());
  }, [initialLang]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBarComponent />
      <Box sx={{ pt: "10px", pl: "20px", pr: "20px", mr: "auto", ml: "auto", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
        <Outlet />
      </Box>
      <Box style={{ height: "50px" }}></Box>
      <FooterComponent changeLangCallback={handleChangeLang} lang={lang} />
    </ThemeProvider >
  );
}

export default App;
