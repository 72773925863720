import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import character from '../resources/character.png';
import TranslatorUtil from '../utils/translator_util';

const HomePage = () => {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("home_page"));

    TranslatorUtil.setCallback("home_page", () => {
        setTexts(TranslatorUtil.getComponentTexts("home_page"));
    });


    const navigate = useNavigate();

    function linkVoices(event) {
        event.preventDefault();
        navigate("../voices");
    }

    function linkAbout(event) {
        event.preventDefault();
        navigate("../about");
    }

    return (
        <Box sx={{ width: "80vw", maxWidth: "600px", mr: "auto", ml: "auto" }}>
            <Box sx={{ width: "80vw", maxWidth: "400px", margin: "0 auto" }} >
                <img src={character} style={{ width: "100%" }} alt="site character" />
            </Box>
            <div style={{ height: "20px" }}></div>
            <Typography variant='h1'>{texts["what_is"]}</Typography>
            <div style={{ height: "20px" }}></div>
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>
                {texts["what_is_p"]}
            </Typography>
            <div style={{ height: "20px" }}></div>
            <Link underline="none" href="/about" onClick={linkAbout}>{texts["more"]}</Link>
            <div style={{ height: "50px" }}></div>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Button variant="contained" color="secondary" href="/voices" onClick={linkVoices}>{texts["voice_library"]}</Button>
            </Box>
        </Box>
    );
}

export default HomePage;

