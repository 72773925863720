import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import logo from '../resources/icon.png';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Link } from '@mui/material';
import MenuUtil from '../utils/menu_util';
import TranslatorUtil from '../utils/translator_util';
import { useNavigate } from 'react-router-dom';


export default function AppBarComponent() {
    const [items, setItems] = React.useState(MenuUtil.getItems());
    const [state, setState] = React.useState(false);

    TranslatorUtil.setCallback("app_bar_component", () => { setItems(MenuUtil.getItems()); });

    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const manualCloseDrawer = (open) => {
        setState(open);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color='transparent' sx={{ boxShadow: 0 }}>
                <Toolbar>
                    <Avatar alt="Remy Sharp" src={logo} />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, ml: 1 }}>
                        <Link
                            href='/'
                            color="inherit"
                            underline="none"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate("../");
                            }}
                        >
                            YouTTS
                        </Link>
                    </Typography>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 0 }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{ width: "80vw", maxWidth: "350px" }}>
                    <List>
                        <Box>
                            {items.map((obj, index) => (
                                <ListItemButton
                                    key={obj.txtKey}
                                    onClick={() => {
                                        manualCloseDrawer(false);
                                        navigate(".." + obj.href);
                                    }}
                                >
                                    <ListItemIcon>
                                        {obj.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={obj.text} />
                                </ListItemButton>
                            ))}
                        </Box>
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}
