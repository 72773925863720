import base from "../resources/translateBase.json";

class TranslatorUtil {
    constructor() {
        this.textObj = {};
        this.callbacks = [];

        this.setLang("en");
    }

    setLang(lang) {
        let effectiveLang = "en";

        if (base["langs_avaliables"].includes(lang)) {
            effectiveLang = lang;
        }

        for (const componentKey in base) {
            if (componentKey !== "langs_avaliables") {
                this.textObj[componentKey] = {};
                for (const textKey in base[componentKey]) {
                    this.textObj[componentKey][textKey] = base[componentKey][textKey][effectiveLang];
                }

            }
        }
        //console.log(this.textObj);
        this.executeAllCallbacks();
    }

    executeAllCallbacks() {
        for (const key in this.callbacks) {
            this.callbacks[key]();
        };
    }

    getComponentTexts(componentKey) {
        return this.textObj[componentKey];
    }

    setCallback(key, callback) {
        this.callbacks[key] = callback;
    }
}

export default new TranslatorUtil();