import { Divider, FormControl, Link, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import MenuUtil from '../utils/menu_util';
import TranslatorUtil from '../utils/translator_util';
import { useNavigate } from 'react-router-dom';


export default function FooterComponent(props) {
    const [items, setItems] = React.useState(MenuUtil.getItems());
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("footer_component"));

    const navigate = useNavigate();

    TranslatorUtil.setCallback("footer_component", () => {
        setItems(MenuUtil.getItems());
        setTexts(TranslatorUtil.getComponentTexts("footer_component"));
    });

    function handleChangeLang(event) {
        let value = event.target.value;

        props.changeLangCallback(value);
    }

    function makeItems(items) {
        let components = [];

        for (let i = 0; i < items.length; i++) {
            components.push(
                <Link
                    underline="none"
                    color="inherit"
                    key={"footer-" + i}
                    href={items[i]["href"]}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(".." + items[i]["href"]);
                    }}
                >
                    {items[i]["text"]}
                </Link>
            );

            if (i < items.length - 1) {
                components.push(<Typography key={"footer-span-" + i}>&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>);
            }

        }

        return components;
    }

    return (
        <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
            <Divider />
            <Box sx={{ height: "15px" }} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {makeItems(items)}
            </Box>
            <Box sx={{ height: "15px" }} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Typography>{texts["language"]}:&nbsp;&nbsp;</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={props.lang}
                        onChange={handleChangeLang}
                        label="Language"
                    >
                        <MenuItem value={"en"}>{texts["english"]}</MenuItem>
                        <MenuItem value={"pt"}>{texts["portuguese"]}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ height: "15px" }} />
        </Box>
    );
}