import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Snackbar, Typography } from '@mui/material';
import CardComponet from '../components/card_component';
import FilterFormComponent from '../components/filterForm_component';
import TranslatorUtil from '../utils/translator_util';

export default function VoicesPage() {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("voices_page"));
    const [isLoaded, setIsLoaded] = useState(false);
    const [voicesData, setVoices] = useState([]);
    const [allVoices, setAllVoices] = useState([]);
    const [snack, setSnack] = useState({ isOpen: false, text: "" });

    TranslatorUtil.setCallback("voices_page", () => { setTexts(TranslatorUtil.getComponentTexts("voices_page")); });

    function getAllVoices(voicesByLang) {
        let all = [];
        let voices;

        for (const key in voicesByLang) {
            voices = voicesByLang[key];
            all.push(...voices.map((objVoice) => {
                return {
                    "name": objVoice["text"],
                    "category": objVoice["category"],
                    "sendBy": objVoice["send_by"],
                    "voice_code": objVoice["code"],
                    "img_url": objVoice["img_url"],
                    "langCode": key
                };
            }));
        }

        return all;
    }

    function handleChangeFilterCallBack(filteredVoices) {
        setVoices(filteredVoices);
    }


    useEffect(() => {
        fetch("api/getListVoices?v=0.2.0&client_id=youtts&token=Wxx9@nCsvh^yU@E1")
            .then(res => res.json())
            .then(
                (result) => {
                    let all = getAllVoices(result);
                    setVoices(all);
                    setAllVoices(all)
                    setIsLoaded(true);
                }
            )
    }, []);

    if (isLoaded) {
        return (
            <Box>
                <div style={{ height: "20px" }}></div>
                <FilterFormComponent allVoices={allVoices} changeFilterCallBack={handleChangeFilterCallBack} />
                <div style={{ height: "20px" }}></div>
                <Divider />
                <div style={{ height: "20px" }}></div>
                <Typography variant='h1'>{texts["voices"]}</Typography>
                <div style={{ height: "20px" }}></div>
                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }} >
                    {
                        voicesData.map((voiceObj) => {
                            //console.log(voiceObj);
                            return <CardComponet
                                key={voiceObj.voice_code}
                                name={voiceObj.name}
                                category={voiceObj.category}
                                sendBy={voiceObj.sendBy}
                                lang={voiceObj.langCode}
                                voiceCode={voiceObj.voice_code}
                                imgUrl={voiceObj.img_url}
                                openSnackCallback={(txt) => { setSnack({ isOpen: true, text: txt }) }} />;
                        })
                    }
                </Box>
                <Snackbar
                    open={snack.isOpen}
                    autoHideDuration={3000}
                    onClose={() => { setSnack(false, "") }}
                    message={snack.text}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="bottomcenter"
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <div style={{ height: "20px" }}></div>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <CircularProgress />
                </Box>
                <div style={{ height: "20px" }}></div>

            </Box>
        );
    }
}

