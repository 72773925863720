import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import TranslatorUtil from '../utils/translator_util';

export default function FilterFormComponent(props) {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("filter_form_component"));
    const [voices, setVoices] = useState(props.allVoices);
    const [selectLangValue, setSelectLangValue] = useState("all");
    const [selectCategoryValue, setSelectCategoryValue] = useState("all");

    TranslatorUtil.setCallback("filter_form_component", () => { setTexts(TranslatorUtil.getComponentTexts("filter_form_component")); });

    const allVoices = props.allVoices;
    const allLang = buildSeleclang(props.allVoices);


    function buildSeleclang(allVoices) {
        let arr = [{ langCode: "all", langName: texts["all_languages"] }];

        allVoices.forEach(voice => {
            let finded = false;
            arr.forEach(item => {
                if (item.langCode === voice.langCode) {
                    finded = true;
                }
            });

            if (!finded) {
                arr.push({ langCode: voice.langCode, langName: voice.langCode === "pt-BR" ? texts["portuguese"] : texts["english"] });
            }
        });

        return arr;
    }

    function handleChangeSelectLang(event) {
        setSelectLangValue(event.target.value);
        setSelectCategoryValue("all");
        handleChangeAllSelects(event.target.value, "all");
    }

    function buildSelecCategory(allVoices) {
        let arr = [{ code: "all", name: texts["all_categories"] }];

        allVoices.forEach(voice => {
            let finded = false;
            let code = _makeCategoryCode(voice.category);

            arr.forEach(item => {
                if (item.code === code) {
                    finded = true;
                }
            });

            if (!finded) {
                arr.push({ code: code, name: voice.category });
            }
        });

        return arr;
    }

    function handleChangeCategory(event) {
        setSelectCategoryValue(event.target.value);
        handleChangeAllSelects(selectLangValue, event.target.value);

    }

    function handleChangeAllSelects(langCode, categoryCode) {
        let voices = allVoices;

        if (langCode !== "all") {
            voices = voices.filter((v) => v.langCode === langCode);
        }

        setVoices(voices);

        if (categoryCode !== "all") {
            voices = voices.filter((v) => _makeCategoryCode(v.category) === categoryCode);
        }

        props.changeFilterCallBack(voices);

    }

    function _makeCategoryCode(categoryName) {
        return categoryName.split(" ").join("-");
    }

    return (
        <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
            <Box sx={{ height: "15px" }} />
            <FormControl fullWidth>
                <InputLabel id="label-lang">{texts["language"]}</InputLabel>
                <Select
                    labelId="label-lang"
                    id="select-lang"
                    value={selectLangValue}
                    label="category"
                    onChange={handleChangeSelectLang}
                >
                    {
                        allLang.map((item) => {
                            return <MenuItem value={item.langCode} key={item.langCode}>{item.langName}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
            <Box sx={{ height: "15px" }} />
            <FormControl fullWidth>
                <InputLabel id="label-category">{texts["category"]}</InputLabel>
                <Select
                    labelId="label-category"
                    id="select-category"
                    value={selectCategoryValue}
                    label="category"
                    onChange={handleChangeCategory}
                >
                    {
                        buildSelecCategory(voices).map((item) => {
                            return <MenuItem value={item.code} key={item.code}>{item.name}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
        </Box >
    );
}