import { AlternateEmail, HelpOutline, Home, Info, Mic } from "@mui/icons-material";
import TranslatorUtil from "./translator_util";

class MenuUtil {
    constructor() {
        this.items = [
            {
                "txtKey": "home",
                "text": "Home",
                "icon": <Home />,
                "href": "/"
            },
            {
                "txtKey": "about",
                "text": "About",
                "icon": <Info />,
                "href": "/about"
            },
            {
                "txtKey": "voices",
                "text": "Voices",
                "icon": <Mic />,
                "href": "/voices"
            },
            {
                "txtKey": "contact",
                "text": "Contact",
                "icon": <AlternateEmail />,
                "href": "/contact"
            },
            {
                "txtKey": "faq",
                "text": "FAQ",
                "icon": <HelpOutline />,
                "href": "/faq"
            }
        ];


    }

    getItems() {
        const texts = TranslatorUtil.getComponentTexts("menu_util");
        let newItems = [];

        this.items.forEach(element => {
            let item = { ...element };
            item["text"] = texts[item["txtKey"]];
            newItems.push(item);
        });

        this.items = [...newItems];
        return this.items;
    }
}

export default new MenuUtil();