import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import TranslatorUtil from '../utils/translator_util';
import character from '../resources/character.png';
import { Mail } from '@mui/icons-material';

const ContactPage = () => {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("contact_page"));

    TranslatorUtil.setCallback("contact_page", () => {
        setTexts(TranslatorUtil.getComponentTexts("contact_page"));
    });

    return (
        <Box sx={{ width: "80vw", maxWidth: "600px", margin: "0 auto" }}>
            <Box sx={{ width: "80vw", maxWidth: "400px", margin: "0 auto" }} >
                <img src={character} style={{ width: "100%" }} alt="site character" />
            </Box>
            <Box sx={{ height: "20px" }} />
            <Typography variant='h1'>{texts["contact_us"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.secondary" }}>
                {texts["text"]}
            </Typography>
            <Box sx={{ height: "50px" }} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyItems: "center", alignItems: "center" }} >
                <Mail sx={{ fontSize: "3rem" }} />
                <Link href="mailto:youtts@proxiedmail.com" color="inherit">youtts@proxiedmail.com</Link>
            </Box>
        </Box >
    );
}

export default ContactPage;
