import { Box, Typography } from '@mui/material';
import React from 'react';

import TranslatorUtil from '../utils/translator_util';
import character from '../resources/character.png';

const FaqPage = () => {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("faq_page"));

    TranslatorUtil.setCallback("faq_page", () => {
        setTexts(TranslatorUtil.getComponentTexts("faq_page"));
    });

    return (
        <Box sx={{ width: "80vw", maxWidth: "600px", margin: "0 auto" }}>
            <Box sx={{ width: "80vw", maxWidth: "400px", margin: "0 auto" }} >
                <img src={character} style={{ width: "100%" }} alt="site character" />
            </Box>
            <Box sx={{ height: "20px" }} />
            <Typography variant='h1'>{texts["faq"]}</Typography>
            <Box sx={{ height: "30px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q1"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a1"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q9"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a9"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q2"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a2"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q3"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a3"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q4"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a4"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q5"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a5"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q10"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a10"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q11"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a11"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q6"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a6"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q7"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a7"]}</Typography>
            <Box sx={{ height: "20px" }} />
            <Typography variant='body1' component="p" sx={{ color: "text.primary", fontWeight: 'bold' }}>{texts["q8"]}</Typography>
            <Typography variant='body2' component="p" sx={{ color: "text.secondary" }}>{texts["a8"]}</Typography>
        </Box >
    );
}

export default FaqPage;
