import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Link } from '@mui/material';
import ClipboardJS from 'clipboard';
import TranslatorUtil from '../utils/translator_util';

export default function CardComponet(props) {
    const [texts, setTexts] = React.useState(TranslatorUtil.getComponentTexts("card_component"));

    TranslatorUtil.setCallback(props.name + "-" + props.accessKey, () => { setTexts(TranslatorUtil.getComponentTexts("card_component")); });

    new ClipboardJS('.btn');

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: "10px", maxWidth: "300px" }}>
            <CardMedia
                component="img"
                sx={{ height: "250px" }}
                image={`voicesPictures/${props.voiceCode}.jpg?v=2`}
                alt="Voice Photo"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {props.name}
                    </Typography>
                    <Box sx={{ height: "5px" }} />

                    <Typography sx={{ fontWeight: 'bold' }} color="text.secondary2" component="div">
                        {texts["category"]}
                    </Typography>
                    <Typography color="text.secondary" component="div">
                        {props.category}
                    </Typography>
                    <Box sx={{ height: "5px" }} />

                    <Typography sx={{ fontWeight: 'bold' }} color="text.secondary2" component="div">
                        {texts["send_by"]}
                    </Typography>
                    <Typography color="text.secondary" component="div">
                        {props.sendBy}
                    </Typography>
                    <Box sx={{ height: "5px" }} />

                    <Typography sx={{ fontWeight: 'bold' }} color="text.secondary2" component="div">
                        {texts["language"]}
                    </Typography>
                    <Typography color="text.secondary" component="div">
                        {props.lang === "pt-BR" ? texts["portuguese"] : texts["english"]}
                    </Typography>
                    <Box sx={{ height: "5px" }} />

                    <Typography sx={{ fontWeight: 'bold' }} color="text.secondary2" component="div">
                        {texts["txt_file"]}
                    </Typography>
                    <Link underline="none" href={"transcriptions/" + props.voiceCode + ".txt"} onClick={
                        (event) => {
                            event.preventDefault();
                            let a = document.createElement("a");
                            a.download = props.voiceCode + ".txt";
                            a.href = "transcriptions/" + props.voiceCode + ".txt";
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                    }>
                        Download
                    </Link>

                    <Box sx={{ height: "5px" }} />

                    <Typography sx={{ fontWeight: 'bold' }} color="text.secondary2" component="div">
                        {texts["voice_key"]}
                    </Typography>
                    <Typography color="text.secondary" component="div">
                        {props.voiceCode}
                    </Typography>

                    <Box sx={{ height: "20px" }} />

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Button
                            className="btn"
                            variant="contained"
                            color="secondary"
                            startIcon={<ContentCopyIcon />}
                            sx={{ width: "100%" }}
                            data-clipboard-text={props.voiceCode}
                            onClick={() => { props.openSnackCallback(texts["snack_message"]) }}
                        >
                            {texts["btn_copy_key"]}
                        </Button>
                    </Box>
                </CardContent>
            </Box>
        </Card>
    );
}